import React, { useState } from 'react';
import HistoryModal from '../VenafiTableUpdate/HistoryModal';
import getHistoryDetails from '../VenafiServiceCalls/getHistoryDetails';

export const columns = [
   {
      name: 'Certificate Name',
      selector: 'cn',
      sortable: true,
      grow:1,
      width:'300px',
   },
   { name: 'Parent DN', selector: 'parent_dn', sortable: true, grow:3, width:'500px',},
   {
      name: 'Expires',
      selector: 'valid_to',
      sortable: true,
      width:'200px',
   },
   {
      name: 'Owner Of Certificate',
      selector: 'cert_owner',
      sortable: true,
      grow: 1,
   },
   {
      name: 'INC Number',
      selector: 'incident_details.latest',
      sortable: true,
      width:'100px',
      cell: (row) =>
         row.incident_details === '' || row.incident_details === undefined || row.incident_details.latest.notification !== "inc"  ? (
            ''
         ) : (
            <a
               href={`https://niketech.service-now.com/incident.do?sys_id=${row.incident_details.latest}`}
            >
               {row.incident_details.latest}
            </a>
         ),
   },
   {
      name: 'Slack Channel',
      selector: 'slack_details.slackChannel',
      sortable: true,
      cell: (row) =>
         row.slack_details === '' || row.slack_details === undefined
            ? ''
            : row.slack_details.slackChannel,
   },
   {
      name: 'Distrubution List',
      selector: 'contact_details.distributionList',
      sortable: true,
      cell: (row) =>
         row.contact_details === '' || row.contact_details === undefined
            ? ''
            : row.contact_details.distributionList,
   },
   {
      name: 'Assignment Group',
      selector: 'snow_details.assignmentGroup',
      sortable: true,
      grow: 1,
      cell: (row) =>
         row.snow_details === '' || row.snow_details === undefined
            ? ''
            : row.snow_details.assignmentGroup,
   },
   {
      name: 'Last Updated By',
      selector: 'lastUpdatedBy',
      sortable: true,
      grow: 1,
   },
   {
      name: 'Notes',
      selector: 'notes',
      sortable: true,
      grow: 1,
   },
];

export function ExpandableRow({ data }) {
   const [modalShow, setModalShow] = useState(false);
   const [historyData, setHistoryData] = useState([]);
   const removeInvalidChars = (input) => {
      return JSON.parse(
         input
            .replaceAll('"{', '{')
            .replaceAll('}"', '}')
            .replaceAll('\\', '')
            .replaceAll("'{", '{')
            .replaceAll("}'", '}')
      );
   };

   const showHistoryModal = () => {
      setModalShow(true);
      const response = getHistoryDetails(
         window.localStorage.getItem('access_token'),
         data.cn,data.valid_to
      ).then((res) => {
         console.log(' <> history response', res);

         if (res && res.body) {
            const data = [];
            res.body.forEach((it) => {
               const param = removeInvalidChars(it.param);
               console.log('   param  ' + JSON.stringify(param));

               data.push({
                  assignmentGroup: param['assignmentGroup'],
                  slackChannel: param['slackChannel'],
                  distributionList: param['distributionList'],
                  changed_by: param.user,
                  notes: param['notes'],
                  force_sync: it.force_sync,
                  changed_time: it.last_updated,
               });
            });
            console.log(data);
            setHistoryData(data);
         }
         return res;
      });
      console.log(' <> history ' + response);
   };

   return (
      <>
         <div>
            <div>
               <HistoryModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  cnname={data.cn}
                  data={historyData}
               />
            </div>
            <div className="border">
               <div className="ncss-container">
                  <div className="ncss-col-sm-12">
                     <div className="ncss-col-sm-4">
                        <h4 className="text-color-accent">
                           Double click the row to Edit.
                        </h4>
                     </div>
                     <h5>
                        <div className="ncss-col-sm-12">
                           <b>CN:</b> {data.cn}
                           <br />
                           <b>Parent DN:</b> {data.parent_dn}
                           <br />
                           <b>Owner:</b> {data.cert_owner}
                           <br />
                           <b>Expires:</b> {data.valid_to}
                           <br />
                        </div>
                        <div className="ncss-col-sm-12">
                           <b>Slack Channel: </b>
                           {data.slack_details
                              ? data.slack_details.slackChannel
                              : ''}
                           <br />
                           <b>Distribution List: </b>
                           {data.contact_details
                              ? data.contact_details.distributionList
                              : ''}
                           <br />
                           <b>Assignment Group: </b>
                           {data.snow_details
                              ? data.snow_details.assignmentGroup
                              : ''}{' '}
                           <br />
                           <b>Last Updated By: </b>
                           {data.lastUpdatedBy ? data.lastUpdatedBy : ''}
                           <br />
                           <b>Notes: </b>
                           {data.notes
                              ? data.notes
                              : ''}
                           <br />
                        </div>
                     </h5>
                     <div className="ncss-col-sm-12">
                        <h4>Incident Details - </h4>
                        <b>Latest Incident Number: </b>
                        {data.incident_details
                           ?     <a
                           href={`https://niketech.service-now.com/incident.do?sys_id=${data.incident_details.latest}`}
                        >
                           {data.incident_details.latest}
                        </a>
                           : ''}
                        <br />
                        <b>Latest Incident Date: </b>
                        {data.incident_details
                           ? data.incident_details.latest_inc_date.date
                           : ''}
                        <br />
                        <button
                           type="button"
                           variant="contained"
                           className="ncss-btn-primary-dark"
                           onClick={showHistoryModal}
                        >
                           Get Update History
                        </button>
                     </div>
                     {console.log('expanded row data', data)}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
